import React from 'react';
import Layout from '../../components/Layout';
import logo from '../../assets/images/logo.png';

// import pic1 from '../../assets/images/pic01.jpg';
// import pic2 from '../../assets/images/pic02.jpg';
// import pic3 from '../../assets/images/pic03.jpg';
// import pic4 from '../../assets/images/pic04.jpg';
// import pic4 from '../../assets/images/el_jefe2.jpg';
// import pic6 from '../../assets/images/pic06.jpg';
// import pic7 from '../../assets/images/pic07.jpg';

import pic1 from '../../assets/images/el_jefe.png';
import pic2 from '../../assets/images/el_jefe_console.jpg';
import pic3 from '../../assets/images/el_jefe_discs.jpg';
import pic4 from '../../assets/images/el_jefe_band.jpg';
import pic5 from '../../assets/images/el_jefe_stage.jpg';
import pic6 from '../../assets/images/hugh_fadal3.jpg';
import pic7 from '../../assets/images/jdc.png';
import pic8 from '../../assets/images/jdc.png';

import config from '../../../config';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Jeff 'El Jefe' Anderson</h2>
          <p>South Texas Blues and Americana</p>
        </div>
      </header>
      <div className="wrapper">
        <div className="inner">
          <section>
            <h3 className="major">The El Jefe Discography</h3>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Release</th>
                    <th>Title</th>
                    <th>Where to Listen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1988</td>
                    <td><b>El Jefe</b></td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://open.spotify.com/album/6IDzQdTPYYWQFNykaJvlG4?si=og-oQrLfRgeL2zZLEPcKWw" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/jeff-el-jefe-anderson-el-jefe" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.amazon.com/albums/B00UDDPOF0?marketplaceId=ATVPDKIKX0DER&musicTerritory=US" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.apple.com/us/album/el-jefe/974761573" className="icon fa-apple">
                            <span className="label">Apple Music</span>
                          </a>
                        </li>



                        
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>1991</td>
                    <td><b>The Fever Sessions</b></td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://open.spotify.com/album/5Tb1g4GjeO0T9G8CIHu8DS?si=WFLgnyRxSFKmh6yOamCM2w" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/jeff-el-jefe-anderson-the" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="" className="icon fa-apple">
                            <span className="label">Apple</span>
                          </a>
                        </li> */}
                        <li>
                          <a href="https://music.amazon.com/albums/B00UDDW520?marketplaceId=ATVPDKIKX0DER&musicTerritory=US" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>1996</td>
                    <td><b>Jefe at Sessions West</b></td>
                    <td>
                      {' '}
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://open.spotify.com/album/0s9WV5ZjN7XyRV0nKvVoYf?si=3XgoVfrJR0yFQNc_vWY0UA" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/jeff-el-jefe-anderson-el-jefe" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.amazon.com/albums/B00T8NRPWQ?marketplaceId=ATVPDKIKX0DER&musicTerritory=US" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>

                        <li>
                          <a href="https://music.apple.com/us/album/jefe-at-sessions-west/964964490" className="icon fa-apple">
                            <span className="label">Apple Music</span>
                          </a>
                        </li>

                        

                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2004</td>
                    <td><b>Shine</b></td>
                    <td>
                      {' '}
                      <ul className="icons icons-header">
                        {/* <li>
                          <a href="/#" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li> */}
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/jeff-el-jefe-anderson-shine" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2010</td>
                    <td><b>3000 Miles</b></td>
                    <td>
                      {' '}
                      <ul className="icons icons-header">
                        {/* <li>
                          <a href="/#" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li> */}
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/3000-miles" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2"></td>
                    <td>

                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </section>

          <section className="artist">
            <h3 className="major">Bio</h3>
            <p>
              Born in Corpus Christi, Texas, in 1957, Jeff was raised by Ted and
              Peggy Anderson, older brother Mark, and sisters Christine and
              Gail.
            </p>
            <span className="image right">
              <img src={pic2} alt="" />
            </span>
            <p>
              Jeff saw The Beatles on the Ed Sullivan Show in 1964, asked for a
              guitar for his 6th birthday, and got a ¾ size acoustic. He picked
              out of couple of licks left-handed, but his first guitar teacher
              (Ed Bennett) suggested he go right-handed. After a couple of years
              he worked up to an Alamo-Fiesta and a Kalamazoo amp, and did his
              first gig in the 4th grade talent show in elementary school.
            </p>
            <p>
              After that, jamming with friends started. By 6th grade, he gigged
              at parties, and by middle school continued with garage bands and
              playing a lot of acoustic with brother Mark, and at his summer
              camp (Echo Valley) in Leakey, Texas.
            </p>

            <span className="image left">
              <img src={pic1} alt="" />
            </span>

            <p>
              In high school Jeff joined a blues-rock band (Rio Grande),
              covering Muddy Waters to ZZ Top, playing school dances and beach
              parties. A very fun band! Back in those days Jeff would be packin’
              a ‘63 Stratocaster, a Gibson SG or Les Paul through an acoustic
              150-B, Sun Septure, or Fender Dual Showmann.
            </p>

            <p>
              Jeff graduated King High School in Corpus Christi a half year
              early because of after-school work credits. He moved to Nashville
              when he was 16 with only his ‘63 Strat, an acoustic Epiphone, and
              a skate board. He joined a really cool Christian Church
              (Maranatha), then joined the church band which was full of
              Nashville’s top session players. He even did a couple of small
              tours in Tennessee, Kentucky and Ohio. Jeff lived in Nashville for
              two years, then moved to Denver, Colorado, for one year where he
              joined the musicians’ union and played some pretty unusual little
              gigs. Jeff then moved back to Texas.
            </p>

            <span className="image right">
              <img src={pic4} alt="" />
            </span>

            <p>
              After enrolling in Del Mar College in Corpus Christi, Jeff studied
              two years of music theory and played in a lab band with Corpus
              Christi’s finest.
            </p>

            <p>
              Next was Cactus Creek, a surfing-country-rock band that did not
              play anywhere that wasn’t on or by a beach. It was with this band
              that Jeff started singing. By this time Jeff had switched to a
              60’s Gold Top Les Paul. Cactus Creek was a regular attraction at
              Inza’s, a honky tonk in Corpus, and played from South Padre Island
              to Galveston.
            </p>

            <p>
              Jeff got an invitation to move to Del Mar, California, and bought
              a plane ticket. The day before his flight was to leave, he was
              asked to audition for Sam Neely, a fine Texas songwriter. Jeff got
              the gig and played six nights a week for the next three years. At
              this time Jeff was ripping his ‘63 Strat, Tobasco Burst Les Paul,
              and Lab Series amp.
            </p>

            <span className="image left">
              <img src={pic5} alt="" />
            </span>

            <p>
              After the Neely band (In It For The Money Band), Sam took a break
              and the fellows stayed together. By now, the early ‘80s were upon
              us. All of the band’s equipment was stolen one night from Jeff’s
              farmhouse garage. A painting truck was spotted outside the
              farmhouse that same night by the band’s soundman. It was the same
              company that was painting the farmhouse (humm). With the help of
              some friends, the Corpus Christi Police Department, and a bunch of
              pissed off band members, the equipment was recovered. It had been
              spread all over West Corpus. Then was the birth of The Detectives.
            </p>

            <p>
              The Detectives was a rock cover band covering The Police, AC-DC,
              Tom Petty, etc. One year later there was a change of members and a
              sax was added. The new Detectives (The Dicks) were funkier and
              jassier, playing complicated songs of Steely Dan, Al Demiola and
              Fussion, to the big dance numbers of Sly and the Family Stone and
              Rick James. The Dicks rocked Cooper’s Alley in Corpus every Monday
              night for two years, and gigged all over town and in San Antonio.
            </p>

            <span className="image right">
              <img src={pic3} alt="" />
            </span>

            <p>
              The Dicks, with some member changes, became Ray Band – a great
              band, but a terrible name. Ray Band had the same format as the
              Dicks, except for a psychedelic ‘60s set, but did not have the
              spark of The Dicks. Ray Band lasted one year.
            </p>

            <p>
              Jeff had started writing songs, but had not been playing them
              live. There was another long line of bands in the early ‘80s.
              Soire, a three-piece power trio; Suckers on Parade, another Steely
              Dan rock band; and Hot Pursuit, the Del Mar jazz band. Jeff played
              a little guitar in that band, and played a Ricky Ricardo bongo
              drum and sang songs in different languages. But he still got to
              blow four or five Larry Carlton solos nightly. Hot Pursuit played
              from Corpus to South Padre Island, and lasted about one year.
            </p>

            <p>
              The next band was another power trio, The Scare. Spooky! The Scare
              played Peter Gabriel to Los Lobos. They went into a small studio
              and demoed four songs – Jeff Beck, etc. It turned out very well.
              He was ready to record some of his own songs. The Scare lasted
              about one year.
            </p>

            <p>
              Jeff then got together with the Sanchez Brothers of the Rockville
              Blues Band. They did an off-shoot named Rockville Rythym. Jeff and
              the Sanchez Brothers would later record in Austin at Lone Star
              Studios. Three of those songs would end up on Jeff’s first CD, El
              Jefe’.
            </p>

            <p>
              Before Jeff would move to Austin, he played in one more band, The
              Technobrats, as a sideman. A B-52 new wave scene. Jeff did not
              last long with a waffle haircut and a guitar with no head stock.
              Change was in the air.
            </p>

            <p>
              With a very exciting invitation from Stevie Ray Vaughn’s Double
              Trouble, Jeff was to move to Austin and start work on his new CD.
              Stevie was in his prime, and Double Trouble was on fire. This was
              very cool and was great for Jefe’s career and the CD. They would
              track four songs at the fire station in San Marcos, and finish the
              songs at Arlyn in Austin, combined with the Rockville sessions and
              other Lone Star sessions. In 1988 Jeff released El Jefe’.
            </p>

            <p>
              Now it was time to put a band together and perform the new CD
              live. They were playing it on the radio, and it was time to move.
              Jeff would stick to his Corpus players, and also use many of
              Austin’s A list. They would play in the next 10 years, every top
              club in Austin and other Texas venues. Also, Blues on the Green
              eight times, Austin Aqua Fests and Antone’s. Jeff also would play
              sideman for Toby Anderson.
            </p>

            <p>
              In 1991 he would record his follow-up to El Jefe’. A very tight
              performance recorded, but not mixed right. A falling-out with the
              producer would be a long road for that recording. Jeff bought the
              master tapes in 1998, then remixed and released it in December
              2004, 13 years later.
            </p>

            <p>
              At the same time, Jeff and long-time pal and Cactus Creek band
              member, Jay Hudson, would build their first studio, Studio 1621,
              which would soon become The Hit Shack. Jeff recorded many songs in
              1621, but none of them are on CD. One interesting project out of
              1621 was Twang Dynasty, instrumental surf music. Jeff would would
              gracefully leave 1621 to add a band partner.
            </p>

            <p>
              Meeting Jon Smith (Boogie Kings, Toto, etc.) at Antone’s one
              night, Jeff and Jon joined forces. A great band only to be killed
              by bad management. Jeff and Jon played many gigs in the next year
              before disarming.
            </p>

            <p>
              Becoming a father in 1992, Conner (named after Eric Clapton’s son)
              would change El Jefe’s live performance calendar. He became
              involved in another studio, Lizard Tracks, but not for long.
            </p>

            <p>
              Then becoming a single parent, Jeff built a home and studio
              outside of Austin in Cedar Valley. The studio was named Sessions
              West. This was his first time to build from the ground up. From
              1996 to 2002, 50 to 60 CDs came out of Sessions, and at least ten
              on JAE Records (Jeff Anderson Enterprises). Jeff and Conner needed
              a change. Jeff’s father, Ted, offered his home in Boerne, Texas.
              Jeff sold his home and studio, and moved to Boerne. Jeff’s sister,
              Gail, and all his neices and his nephew, Austin, were in Boerne.
            </p>

            <p>
              In 2002 Jeff and Conner moved to Tapatio Springs. Jeff then built
              Sessions West II in an office-type building outside of town. Many
              great sounds are currently coming out of Sessions West II
              Recording, Inc. Soon there after, he released his two CDs – The
              Fever Sessions, from 1991, and Shine, from 2004.
            </p>
			
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
